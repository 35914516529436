<template>
  <section class="whychoose-1 secpadd">
    <div class="container">
      <div class="row quote1forms">
        <form @submit.prevent="resetPassword">
          <div class="col-md-6 col-md-offset-3">
            <div class="field">
              <label>{{ getTranslation('password') }}<span class="require">*</span></label>
              <input class="form-control" :placeholder="getTranslation('password')" :class="{'is-invalid':$v.form.password.$error}" type="password" v-model.trim="form.password">
              <div v-if="$v.form.password.$error" class="invalid-feedback">
                {{ getTranslation('requiredField') }}
              </div>
            </div>
            <div class="field margtop30">
              <label>{{ getTranslation('password_confirmation') }}<span class="require">*</span></label>
              <input class="form-control" :placeholder="getTranslation('password_confirmation')" :class="{'is-invalid':$v.form.password_confirmation.$error}" type="password"
                     v-model.trim="form.password_confirmation">
              <div v-if="$v.form.password_confirmation.$error" class="invalid-feedback">
                {{ getTranslation('requiredField') }}
              </div>
            </div>
          </div>

          <div class="col-md-6 col-md-offset-3 margtop30">
            <p class="field submit">
              <input :value="getTranslation('save')" @click.prevent="resetPassword" class="fh-btn" type="submit">
            </p>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import required from "vuelidate/lib/validators/required";

export default {
  name: "ResetPassword",
  data() {
    return {
      form: {
        password: '',
        password_confirmation: '',
      }
    }
  },
  methods: {
    resetPassword() {
      this.$v.form.$touch();

      if ( this.$v.form.$invalid )
        return;

      this.form.expires = this.$route.query.expires;
      this.form.signature = this.$route.query.signature;

      let params={
        expires:this.$route.query.expires,
        signature:this.$route.query.signature
      };

      this.axios.post(this.$urls.auth.resetPassword.url.replace(':id', this.$route.query.id), this.form,{params:params})
          .then(() => {
            Swal.fire({
              icon: 'success',
              title: this.getTranslation('Your password is reset'),
            });
            this.$router.push({ path: '/' });
          })
          .catch(() => {
          })

    }
  },
  validations: {
    form: {
      password: {
        required
      },
      password_confirmation: {
        required
      },
    }
  }
}
</script>

<style scoped>

</style>